import React from 'react'
import {
  ActionButtonVariant,
  MoreBar,
  MoreBarActionProps,
  Tooltip,
  TooltipProps,
  useTooltip,
} from '@revolut/ui-kit'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'

export const BULK_EDIT_LIMIT = 10000

export type BulkEditActionProps = {
  actionProps?: Partial<MoreBarActionProps>
  children: React.ReactNode
  count: number
  handleClick: () => void
  pending?: boolean
  tooltipContent: React.ReactNode
  tooltipProps?: Partial<TooltipProps>
  useIcon?: ResolveIconType
  variant?: ActionButtonVariant
}

export const BulkEditAction = ({
  children,
  count,
  handleClick,
  pending,
  tooltipContent,
  variant,
  useIcon,
  actionProps = {},
  tooltipProps = {},
}: BulkEditActionProps) => {
  const tooltip = useTooltip()
  const isDisabled = !count || count > BULK_EDIT_LIMIT

  return (
    <MoreBar.Action
      aria-disabled={isDisabled}
      onClick={!isDisabled ? handleClick : () => {}}
      pending={pending}
      variant={variant}
      useIcon={useIcon}
      {...tooltip.getAnchorProps()}
      {...actionProps}
    >
      {children}
      {count > BULK_EDIT_LIMIT && (
        <Tooltip maxWidth={450} {...tooltip.getTargetProps()} {...tooltipProps}>
          {tooltipContent}
        </Tooltip>
      )}
    </MoreBar.Action>
  )
}
