import React, { PropsWithChildren } from 'react'
import {
  Box,
  Flex,
  VStack,
  Text,
  Cell,
  Tag,
  Token,
  HStack,
  Icon,
  IconName,
  Avatar,
} from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import { IdStatuses } from '@src/interfaces/employees'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { getStatusColor } from '@src/components/CommonSC/General'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import upperFirst from 'lodash/upperFirst'
import { removeUnderscore } from '@src/utils/string'

interface PageHeaderTitleProps {
  title: React.ReactNode
  labels: React.ReactNode
  actions: React.ReactNode
  useIcon?: IconName
}

export const PageHeaderTitle = ({
  title,
  labels,
  actions,
  useIcon,
}: PageHeaderTitleProps) => {
  return (
    <Box mt="s-8">
      <Cell pt="s-24" px="s-16" pb="s-16">
        <VStack gap="s-16" overflow="hidden">
          <HStack gap="s-16">
            {useIcon ? <Avatar size={56} useIcon={useIcon} /> : null}
            <Flex flex={1} alignItems="center" gap="s-16">
              <VStack gap="s-4">
                <Text variant="h1" whiteSpace="pre-wrap" data-testid="page_header_title">
                  {title}
                </Text>
                <Flex flexWrap="wrap" gap={['s-2', 's-8']}>
                  {labels}
                </Flex>
              </VStack>
            </Flex>
          </HStack>
          <Flex gap="s-8">{actions}</Flex>
        </VStack>
      </Cell>
    </Box>
  )
}

type StatusTagProps = {
  status?: Statuses | IdStatuses | ApprovalStatuses
  useIcon?: IconName
}

export const StatusTag = ({
  status,
  useIcon,
  children,
}: PropsWithChildren<StatusTagProps>) => {
  if (!status) {
    return null
  }
  return (
    <Tag
      useIcon={useIcon}
      variant="outlined"
      color={getStatusColor(status) || Token.color.greyTone50}
    >
      {children ?? upperFirst(removeUnderscore(status))}
    </Tag>
  )
}

type SubtitleProps = {
  iconName?: IconName
}

export const Subtitle = ({ iconName, children }: PropsWithChildren<SubtitleProps>) => {
  return (
    <HStack align="center">
      {iconName && <Icon name={iconName} color={Token.color.greyTone50} size={15} />}
      <Text color={Token.color.foreground_80} variant="caption" textDecoration="none">
        {children}
      </Text>
    </HStack>
  )
}

type SubtitleLinkProps = SubtitleProps & {
  to: string
}

export const SubtitleLink = ({
  iconName,
  to,
  children,
}: PropsWithChildren<SubtitleLinkProps>) => {
  return (
    <HStack align="center">
      {iconName && <Icon name={iconName} color={Token.color.greyTone50} size={15} />}
      <Text
        use={InternalLink}
        to={to}
        target="_blank"
        color={Token.color.foreground_80}
        variant="caption"
        textDecoration="none"
      >
        {children}
      </Text>
    </HStack>
  )
}
