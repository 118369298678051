import React, { useState } from 'react'
import { Group, Tag, Text, Token } from '@revolut/ui-kit'
import {
  HeaderItem,
  HeaderMenu,
} from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/HeaderItem'
import {
  JobPostingInterface,
  JobPostingLocationInterface,
  PublishingStatuses,
} from '@src/interfaces/jobPosting'
import ChangeJobPosting from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/ChangeJobPosting'
import DOMPurify from 'dompurify'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import TextWithMoreCount from '@src/components/TextWithMoreCount/TextWithMoreCount'
import CandidateCount from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/CandidateCount'
import { Statuses } from '@src/interfaces'

const getStatusColor = (status: PublishingStatuses) => {
  switch (status) {
    case PublishingStatuses.closed:
    case PublishingStatuses.unpublished:
      return Token.color.red
    default:
      return Token.color.green
  }
}

type LocationDetailsProps = {
  label: string
  locations: JobPostingLocationInterface[]
}

const LocationDetails = ({ label, locations }: LocationDetailsProps) => {
  if (!locations.length) {
    return null
  }
  return (
    <FormPreview.Item<JobPostingInterface>
      title={label}
      field="locations"
      insert={() => (
        <TextWithMoreCount
          items={locations.map(({ location_name }) => location_name)}
          visibleCount={3}
          expandable
        />
      )}
    />
  )
}

type JobPostingDescriptionProps = {
  jobPosting: JobPostingInterface
}

const getJobPostingStatusIcon = (status: Statuses | PublishingStatuses) => {
  switch (status) {
    case PublishingStatuses.unpublished:
    case PublishingStatuses.closed:
      return 'ExclamationTriangle'
    case PublishingStatuses.published_internally:
    case PublishingStatuses.published_externally:
    case PublishingStatuses.fully_published:
      return '16/Live'
    default:
      return undefined
  }
}

const JobPostingDescription = ({ jobPosting }: JobPostingDescriptionProps) => {
  const locations = jobPosting.locations ?? []
  return (
    <>
      <FormPreview<JobPostingInterface> data={jobPosting}>
        <FormPreview.Item<JobPostingInterface>
          title="Status"
          field="status"
          insert={data => (
            <Tag
              useIcon={getJobPostingStatusIcon(data.status)}
              variant="outlined"
              color={getStatusColor(data.status)}
            >
              {data.status_display}
            </Tag>
          )}
        />
        <LocationDetails
          label="Office"
          locations={locations.filter(({ type }) => type === 'office')}
        />
        <LocationDetails
          label="Remote"
          locations={locations.filter(({ type }) => type === 'remote')}
        />
        {(jobPosting.sections ?? []).map(section => (
          <FormPreview.Details<JobPostingInterface>
            key={section.title}
            title={section.title}
            insert={() => (
              <Text
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(section.content),
                }}
              />
            )}
          />
        ))}
      </FormPreview>
    </>
  )
}

type OverviewProps = {
  jobPosting: JobPostingInterface
  onRefresh: () => Promise<void>
}

const Overview = ({ jobPosting, onRefresh }: OverviewProps) => {
  const [openJobPostingSidebar, setOpenJobPostingSidebar] = useState(false)
  return (
    <>
      <Group>
        <HeaderItem
          title={jobPosting.name}
          side={
            <HeaderMenu
              jobPostingId={jobPosting?.id}
              label="Actions"
              useIcon="ChevronDown"
              onConnectJobPosting={() => {
                setOpenJobPostingSidebar(true)
              }}
            />
          }
        />
        <CandidateCount />
        <JobPostingDescription jobPosting={jobPosting} />
      </Group>
      <ChangeJobPosting
        open={openJobPostingSidebar}
        selectedJobPostingId={jobPosting?.id}
        onClose={() => {
          setOpenJobPostingSidebar(false)
        }}
        onRefresh={onRefresh}
      />
    </>
  )
}

export default Overview
