import { FetchIdsResponseInterface } from '@src/interfaces/bulkEdit'
import { FilterByInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'
import { groupFiltersIntoURLQuery } from '@src/utils/table'

export const useFetchIds = (apiEndpoint: string, filters: FilterByInterface[]) =>
  useFetch<FetchIdsResponseInterface>(`${apiEndpoint}/ids`, undefined, {
    params: {
      ...groupFiltersIntoURLQuery(filters),
    },
  })
