import React, { useRef, useState } from 'react'
import {
  ActionButton,
  ActionMenu,
  Avatar,
  Button,
  IconName,
  Item,
  ItemSkeleton,
  MoreBar,
  StatusPopup,
  Token,
  useStatusPopup,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { updateJobDescription } from '@src/api/jobPosting'
import { useGetJobPostingSettings, useGlobalSettings } from '@src/api/settings'
import PublishButton from '../Components/PublishButton'
import { useCareersSettings } from '@src/api/recruitment/careers'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useLapeContext } from '@src/features/Form/LapeForm'

const PublishStatusIcon = ({ requisitionCount }: { requisitionCount: number }) => {
  const { values } = useLapeContext<JobPostingInterface>()
  if (!requisitionCount) {
    return <Avatar color={Token.color.red} useIcon="ExclamationTriangle" />
  }
  switch (values.status) {
    case PublishingStatuses.unpublished:
      return <Avatar useIcon="EyeHide" />
    case PublishingStatuses.closed:
      return <Avatar color={Token.color.orange} useIcon="ExclamationTriangle" />
    case PublishingStatuses.published_internally:
    case PublishingStatuses.published_externally:
    case PublishingStatuses.fully_published:
      return <Avatar color={Token.color.green} useIcon="CheckSuccess" />
    default:
      return null
  }
}

type PublishMenuProps = {
  label?: string
  useIcon?: IconName
}

const PublishMenu = ({ label, useIcon }: PublishMenuProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const statusPopup = useStatusPopup()
  const onSuccess = () => {
    statusPopup.show(
      <StatusPopup variant="success-result">
        <StatusPopup.Title>Updated job posting status</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const onError = (msg: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{msg}</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const handleChangeStage = async (status: PublishingStatuses) => {
    setLoading(true)
    try {
      const res = await updateJobDescription({ status }, { id: String(values.id) })
      values.status = res.data.status
      values.status_display = res.data.status_display
      onSuccess()
    } catch (error) {
      const errorsData = error?.response?.data
      const statusError = errorsData?.status?.[0]
      const compensationBandsError = errorsData?.salary_bands_by_location
      onError(
        statusError || compensationBandsError || 'Error updating job posting status',
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <MoreBar.Action
        useIcon={useIcon ?? 'EyeShow'}
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        pending={loading}
        disabled={loading}
      >
        {label ?? 'Publish'}
      </MoreBar.Action>
      <ActionMenu open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        <ActionMenu.Group>
          <ActionMenu.Item
            useIcon="Globe"
            disabled={values.status === PublishingStatuses.fully_published}
            onClick={() => {
              handleChangeStage(PublishingStatuses.fully_published)
            }}
          >
            Publish Internally & Externally
          </ActionMenu.Item>
          <ActionMenu.Item
            useIcon="Services"
            disabled={values.status === PublishingStatuses.published_internally}
            onClick={() => {
              handleChangeStage(PublishingStatuses.published_internally)
            }}
          >
            Publish Internally
          </ActionMenu.Item>
          <ActionMenu.Item
            useIcon="16/LinkExternal"
            disabled={values.status === PublishingStatuses.published_externally}
            onClick={() => {
              handleChangeStage(PublishingStatuses.published_externally)
            }}
          >
            Publish Externally
          </ActionMenu.Item>
          <ActionMenu.Item
            useIcon="EyeHide"
            color={Token.color.red}
            disabled={values.status === PublishingStatuses.closed}
            onClick={() => {
              handleChangeStage(PublishingStatuses.closed)
            }}
          >
            Unpublish
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}

type PublishActionProps = {
  label?: string
  useIcon?: IconName
}

export const PublishAction = ({ label, useIcon }: PublishActionProps) => {
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { data: careerPageSettings } = useCareersSettings()
  if (!careerPageSettings?.publish_career_website) {
    return null
  }
  if (jobPostingSettings?.enable_all_publishing_statuses) {
    return <PublishMenu label={label} useIcon={useIcon} />
  }
  return <PublishButton />
}

type PublishStatusProps = {
  loading: boolean
  openRequisitionCount: number
  pendingRequisitionCount: number
}

const PublishStatus = ({
  loading,
  openRequisitionCount,
  pendingRequisitionCount,
}: PublishStatusProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { isLoading: loadingGlobalSettings } = useGlobalSettings()
  const isCommercial = useIsCommercial()
  const { data, isLoading: loadingCareerSettings } = useCareersSettings()

  if (loadingGlobalSettings || loadingCareerSettings || loading) {
    return <ItemSkeleton />
  }

  if (isCommercial && !data?.publish_career_website) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar color={Token.color.red} useIcon="ExclamationTriangle" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Careers website is not published</Item.Title>
          <Item.Description>
            The application form is currently unavailable to candidates. <br /> Please
            publish Careers website in the settings to start receiving applications.
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            useIcon="IndustrialGear"
            to={pathToUrl(ROUTES.SETTINGS.CAREER_PAGE)}
            use={InternalLink}
          >
            Go to Settings
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  const getDescription = () => {
    if (!openRequisitionCount) {
      return 'Job posting requires requisition(s) to be published'
    }
    switch (values.status) {
      case PublishingStatuses.unpublished:
        return 'Job posting is unpublished and not available for candidates'
      case PublishingStatuses.closed:
        return 'Job posting is not visible for candidates'
      case PublishingStatuses.published_internally:
      case PublishingStatuses.published_externally:
      case PublishingStatuses.fully_published:
        return values.status_display
      default:
        return null
    }
  }
  const isPublished =
    values.status !== PublishingStatuses.unpublished &&
    values.status !== PublishingStatuses.closed
  const getTitle = () => {
    if (!openRequisitionCount) {
      if (!pendingRequisitionCount) {
        return 'No requisitions are connected'
      }
      return 'Connected requisition(s) are not opened'
    }
    return isPublished ? 'Published' : 'Not published'
  }
  return (
    <Item>
      <Item.Avatar>
        <PublishStatusIcon requisitionCount={openRequisitionCount} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{getTitle()}</Item.Title>
        <Item.Description>{getDescription()}</Item.Description>
      </Item.Content>
      <Item.Side>
        <PublishAction label="Update" useIcon="ChevronDown" />
      </Item.Side>
    </Item>
  )
}

export default PublishStatus
