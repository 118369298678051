import { useMemo } from 'react'
import { useFetchIds } from '@src/api/bulkEdit'
import { FilterByInterface } from '@src/interfaces/data'
import { SelectTableWrapperOnChangeData } from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'

export const useGetBulkEditData = <T extends { id: number }>(
  apiEndpoint: string,
  filters: FilterByInterface[],
  tableData: T[],
  selectedData?: SelectTableWrapperOnChangeData<T>,
) => {
  const { data: idsData } = useFetchIds(apiEndpoint, filters)

  const bulkCount = useMemo(() => {
    if (selectedData?.isAllSelected) {
      return idsData?.count
        ? idsData?.count - selectedData.excludeListIds.size
        : tableData.length - selectedData.excludeListIds.size
    }

    return selectedData?.selectedRowsData.length || 0
  }, [selectedData, tableData])

  const bulkIds = useMemo(() => {
    if (selectedData?.selectedRowsIds.size) {
      return Array.from(selectedData?.selectedRowsIds).map(id => Number(id))
    }

    if (selectedData?.isAllSelected && idsData?.ids) {
      return idsData.ids.filter(unit => !selectedData.excludeListIds.has(String(unit)))
    }

    if (selectedData?.isAllSelected && !idsData?.ids) {
      return tableData
        .filter(unit => !selectedData.excludeListIds.has(String(unit.id)))
        .map(({ id }) => Number(id))
    }

    return []
  }, [selectedData, tableData])

  return { bulkCount, bulkIds }
}
