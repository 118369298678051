import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { patchJobDescriptionOfRequisition } from '@src/api/requisitions'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import ChangeJobPostingSidebar from '@src/pages/Forms/JobPosting/Components/ChangeJobPostingSidebar'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'

type ChangeJobPostingProps = {
  open: boolean
  selectedJobPostingId?: number
  onClose: () => void
  onRefresh: () => Promise<void>
}

const ChangeJobPosting = ({
  open,
  selectedJobPostingId,
  onClose,
  onRefresh,
}: ChangeJobPostingProps) => {
  const { values } = useLapeContext<RequisitionInterface>()
  const statusPopup = useStatusPopup()
  const handleJobPostingChange = async (newJobPosting?: JobPostingInterface) => {
    onClose()
    if (newJobPosting) {
      const result = await patchJobDescriptionOfRequisition(values?.id, {
        job_posting: { id: newJobPosting?.id },
      })

      if (result.data) {
        await onRefresh()
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Job posting has changed</StatusPopup.Title>
          </StatusPopup>,
        )
      }
    }
  }
  return (
    <ChangeJobPostingSidebar
      onClose={() => {
        onClose()
      }}
      selectedJobPostingId={selectedJobPostingId}
      specialisationId={values?.specialisation?.id}
      onChange={handleJobPostingChange}
      isOpen={open}
    />
  )
}
export default ChangeJobPosting
